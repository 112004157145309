import React, { useState } from "react";
import {
  HeroSlide,
  HeroBtnWrapper,
  HeroContent,
  HeroP,
  HeroImgContainer,
  HeroContainer,
  AnimatedContainer,
} from "./HeroElements";
import AboutImg from "../../assets/sections-images/about-img.png";
import AboutImg2x from "../../assets/sections-images/about-img@2x.png";
import About2Img from "../../assets/sections-images/about2-img.png";
import About2Img2x from "../../assets/sections-images/about2-img@2x.png";
import About3Img from "../../assets/sections-images/about3-img.png";
import About3Img2x from "../../assets/sections-images/about3-img@2x.png";
import { animated, useTransition } from "react-spring";
import { useSwipeable } from "react-swipeable";
import { SectionHeader } from "../SectionHeader";
import LogoSliderNav from "../LogoSliderNav";

import MotywacjaIcon from '../../assets/icons/motywacja.png'
import InformacjaIcon from '../../assets/icons/informacja.png'
import FunkcjonalnoscIcon from '../../assets/icons/funkcjonalnosc.png'

const AnimatedDisplay = ({
  item,
  activeSlide,
  slides,
  setActiveSlide,
}: {
  item: {
    id: number;
    title: string;
    subtitle: string;
    content: string;
    image: string;
    image2x: string;
  };
  activeSlide: number;
  slides: {
    id: number;
    title: string;
    subtitle: string;
    content: string;
    image: string;
    image2x: string;
  }[];
  setActiveSlide: (index: number) => void;
}) => {
  const transition = useTransition(item, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  } as React.CSSProperties);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (activeSlide === slides.length - 1) {
        setActiveSlide(0);
      } else {
        setActiveSlide(activeSlide + 1);
      }
    },
    onSwipedRight: () => {
      if (activeSlide === 0) {
        setActiveSlide(slides.length - 1);
      } else {
        setActiveSlide(activeSlide - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <AnimatedContainer {...handlers}>
      {transition((style, item) => (
        <animated.div key={item.id} style={style}>
          <HeroSlide>
            <HeroContent>
              <HeroP
                dangerouslySetInnerHTML={{
                  __html: item.content,
                }}
              />
              <HeroBtnWrapper>
                {/* <ArrowButton
                  type="button"
                  onClick={() => {
                    if (activeSlide === 0) {
                      setActiveSlide(slides.length - 1);
                    } else {
                      setActiveSlide(activeSlide - 1);
                    }
                  }}
                >
                  ❮
                </ArrowButton> */}
                {/* <HeroButton
                  onClick={() => {
                    if (activeSlide === slides.length - 1) {
                      setActiveSlide(0)
                    } else {
                      setActiveSlide(activeSlide + 1)
                    }
                  }}
                >
                  Więcej <ArrowRight />
                </HeroButton> */}
                {/* <PaginationContainer>
                  {slides.map((_, i) => {
                    if (i === activeSlide) {
                      return <PaginationButtonActive />;
                    }
                    return (
                      <PaginationButton onClick={() => setActiveSlide(i)} />
                    );
                  })}
                </PaginationContainer>
                <ArrowButton
                  type="button"
                  onClick={() => {
                    if (activeSlide === slides.length - 1) {
                      setActiveSlide(0);
                    } else {
                      setActiveSlide(activeSlide + 1);
                    }
                  }}
                >
                  ❯
                </ArrowButton> */}
              </HeroBtnWrapper>
            </HeroContent>
            <HeroImgContainer>
              <img src={item.image} srcSet={`${item.image2x} 2x`} alt="" />
            </HeroImgContainer>
          </HeroSlide>
        </animated.div>
      ))}
    </AnimatedContainer>
  );
};

const HeroSection = () => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const aboutUsSlides: {
    id: number;
    title: string;
    subtitle: string;
    content: string;
    image: string;
    image2x: string;
    icon: string;
  }[] = [
    {
      id: 1,
      title: "TWORZYMY",
      subtitle: "GRY IDEALNE",
      content:
        "Sukcesy naszych projektów grywalizacyjnych 🏆 oparte są na idealnym połączeniu trzech elementów składowych:<p style='margin-bottom: 8px; margin-top: 8px;'>✅ dopasowanego do specyfiki organizacji <span class='text-yellow'>scenariusza i fabuły gry</span>&nbsp;🎮,</p><p style='margin-bottom: 8px;'>✅ zestawu narzędzi i <span class='text-yellow'>rozwiązań wspierających motywację</span> i zaangażowanie, całego zespołu jak i każdego uczestnika z osobna&nbsp;👍,</p><p>✅ <span class='text-yellow'>ergonomii dostępu użytkownika do danych</span> biznesowych, na których opiera się grywalizacja📱.</p>",
      image: AboutImg,
      image2x: AboutImg2x,
      icon: MotywacjaIcon,
    },
    {
      id: 2,
      title: "TWORZYMY",
      subtitle: "GRY IDEALNE",
      content:
        "Przygotujemy dla Ciebie <span class='text-yellow'>indywidualny scenariusz&nbsp;🖋, unikalną kreację&nbsp;🎨 oraz rozwiązania</span>&nbsp;⚙, które odzwierciedlać będą potrzeby biznesowe i specyfikę Twojej firmy.<p style='margin-bottom: 8px; margin-top: 8px;'>✅ Poprzez <span class='text-yellow'>panel administracyjny</span> będziesz zarządzać grą oraz monitorować na bieżąco jej przebieg i wyniki.&nbsp;🧐</p><p style='margin-bottom: 8px; margin-top: 8px;'>✅ Nie zapomnimy przy tym o Uczestnikach. Ciekawa fabuła zapewni zainteresowanie i zaangażowanie, a <span class='text-yellow'>funkcjonalna aplikacja mobilna</span> wysoką efektywność.</p>",
      image: About2Img,
      image2x: About2Img2x,
      icon: InformacjaIcon,
    },
    {
      id: 3,
      title: "TWORZYMY",
      subtitle: "GRY IDEALNE",
      content:
        "💬&nbsp;chatBox, 📗&nbsp;eduBox, 🎯&nbsp;misje specjalne, 🔥&nbsp;aktywatory w wyzwaniach to tylko kilka wybranych, opracowanych przez nas <span class='text-yellow'>mechanizmów windujących realizację celów i zadań</span>&nbsp;📈 do niespotykanych wcześniej poziomów.<br /><br />▶ Wskaźniki, 📊&nbsp;wykresy, 📋&nbsp;karta klienta to z kolei przykładowe <span class='text-yellow'>narzędzia usprawniające dostęp graczy do niezbędnych informacji</span>.",
      image: About3Img,
      image2x: About3Img2x,
      icon: FunkcjonalnoscIcon,
    },
  ];

  return (
    <HeroContainer id="onas">
      <div style={{ margin: "150px auto 0 auto", width: "100%" }}>
        <SectionHeader>
          TWORZYMY <span className="text-yellow">GRYWALIZACJE KOMPLETNE</span>
        </SectionHeader>
        <LogoSliderNav
          activeIndex={activeSlide}
          slides={aboutUsSlides}
          setActiveSlide={(slide) => {
            setActiveSlide(slide);
          }}
      />
      </div>
      <AnimatedDisplay
        item={aboutUsSlides[activeSlide]}
        activeSlide={activeSlide}
        slides={aboutUsSlides}
        setActiveSlide={(slide) => {
          setActiveSlide(slide);
        }}
      />
    </HeroContainer>
  );
};

export default HeroSection;
