import styled from 'styled-components'

export const ContactContainer = styled.div`
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  max-width: 1500px;
  margin: 150px auto 0 auto;
  min-height: 500px;

  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const ContactWrapperTop = styled.div`
  // max-width: 1000px;
  margin: 0 auto;
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`

export const ContactWrapperBottom = styled.div`
  // max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`

export const ContactCol = styled.div`
  display: flex;
  justify-content: center;
`

export const TopImgContainer = styled.div`
  img {
    max-width: 130px;
    @media screen and (max-width: 768px) {
      max-width: 100px;
      max-height: 100px;
    }
  
    @media screen and (max-width: 576px) {
      max-width: 70px;
      max-height: 70px;
    }
  }
`

export const TopTxtContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
  }
  @media screen and (max-width: 576px) {
    font-size: 1rem;
    word-break: break-all;
  }
`

export const ContactH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`
