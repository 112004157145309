import styled from 'styled-components'

export const TeamContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
  margin: 150px auto 0 auto;

  // @media screen and (max-width: 768px) {
  //   height: 1100px;
  // }

  // @media screen and (max-width: 480px) {
  //   height: 1300px;
  // }
`

export const TeamWrapper = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  align-items: center;
  padding: 0 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
`

export const TeamCarouselWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  color: #fff;
  margin-top: 50px;
  .rec-arrow {
    margin: 0 15px;
    &:enabled {
      background-color: #ffc20e !important;
    }
    &:disabled {
      background-color: #ffc20e !important;
      opacity: 0.1 !important;
      color: black;
    }
    @media screen and (max-width: 768px) {
      margin: 0 10px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      min-width: 40px;
      font-size: 1.4em;
    }
  
    @media screen and (max-width: 480px) {
      margin: 0 5px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      min-width: 30px;
      font-size: 1.2em;
    }
  }
  .rec-dot {
    background-color: #fff;
    width: 15px;
    height: 15px;
    box-shadow: none;
    &_active {
      background-color: #ffc20e !important;
      box-shadow: none;
    }
    @media screen and (max-width: 480px) {
      width: 10px;
      height: 10px;
    }
  }
`

export const TeamH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`
export const Item = styled.div`
  text-align: left;
  padding: 0 25px;
  img {
    padding-bottom: 20px;
    width: 100%;
    margin: 0 auto;
  }
  h4 {
    font-weight: normal;
    margin-bottom: 2px;
  }
  h6 {
    font-weight: normal;
    margin-bottom: 10px;
    font-size: 80%;
  }
  p {
    font-size: 85%;
    line-height: 22px;
  }
`

export const HrLine = styled.hr`
  width: 60%;
  border: 1px solid #ffc20e;
  margin-bottom: 5px;
`
