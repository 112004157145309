import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

interface NavProps {
  readonly scrollNav?: boolean;
}

export const Nav = styled.nav<NavProps>`
  background: rgb(31,29,30);
  background: linear-gradient(180deg, rgba(31,29,30,1) 0%, rgba(31,29,30,0.9) 45%, rgba(31,29,30,0) 100%);
  height: 150px;
  margin-top: -151px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 1em;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transiton: 0.8s all ease;
  }
`

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  // max-width: 1100px;
  max-width: 1500px;
`

export const NavLogo = styled(LinkR)`
  color: #ffc20e;
  justify-content: center;
  cursor: pointer;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: normal;
  text-decoration: none;
  flex-direction: column;

  img {
    padding-bottom: 3px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    img {
      height: 26px;
    }
    span {
      font-size: 0.85rem;
    }
  }
`

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavItem = styled.li`
  height: 100px;
`

export const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  border-bottom: 3px solid rgba(255, 255, 255, 0);
  text-transform: uppercase;

  &.active {
    border-bottom: 3px solid #ffc20e;
  }
  svg {
    margin-bottom: 5px;
    width: 30px;
    height: 30px;
    fill: #ffc20e;
  }
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #ffc20e;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`
