import React from 'react'
import {
  CloseIcon,
  Icon,
  SidebarContainer,
  SidebarLink,
  SidebarMenu,
  SidebarWrapper,
} from './SidebarElements'

type SidebarProps = {
  isOpen: boolean
  toggle: () => void
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="onas" onClick={toggle} spy hashSpy offset={-100}>
            O nas
          </SidebarLink>
          <SidebarLink to="projekty" onClick={toggle} spy hashSpy offset={-100}>
            Projekty
          </SidebarLink>
          <SidebarLink to="zespol" onClick={toggle} spy hashSpy offset={-100}>
            Zespół
          </SidebarLink>
          <SidebarLink to="kontakt" onClick={toggle} spy hashSpy offset={-100}>
            Kontakt
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
