import React from 'react'
import styled from 'styled-components'
import { ArrowButton } from './ArrowButton'


const LogoSliderNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
`

const SliderIcons = styled.div`
  display: flex;
  align-ttems: center;
  justify-content: space-evenly;
  height: 50px;
  width: 100%;
  @media screen and (max-width: 480px) {
    height: 30px;
  }
`

const SliderIcon = styled.div`
  img {
    filter: grayscale(1);
    opacity: 0.5;
    height: 100%;
    transition: all 0.25s ease-in-out;
  }
  img.active {
    opacity: 1;
    filter: none;
  }
`

type LogoSliderNavProps = {
  activeIndex: number
  slides: {
    id: number;
    content: string;
    image: string;
    image2x: string;
    icon: string;
  }[];
  setActiveSlide: (index: number) => void
}

const LogoSliderNav: React.FunctionComponent<LogoSliderNavProps> = ({activeIndex = 0, setActiveSlide, slides}) => {
  return (
    <LogoSliderNavContainer>
      <SliderIcons>
        <ArrowButton type="button" onClick={() => {
          if (activeIndex === 0) {
            setActiveSlide(slides.length - 1);
          } else {
            setActiveSlide(activeIndex - 1);
          }
        }}>❮</ArrowButton>
        {slides.map((slide, i) => (
          <SliderIcon>
            <img src={slide.icon} alt="" className={activeIndex === i ? 'active' : ''} />
          </SliderIcon>
        ))}
        <ArrowButton type="button" onClick={() => {
          if (activeIndex === slides.length - 1) {
            setActiveSlide(0);
          } else {
            setActiveSlide(activeIndex + 1);
          }
        }}>❯</ArrowButton>
      </SliderIcons>
    </LogoSliderNavContainer>
  )
}

export default LogoSliderNav
