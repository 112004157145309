import React, { useEffect, useState } from 'react'
import { FaBars } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import { animateScroll as scroll } from 'react-scroll'
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
} from './NavbarElements'
import tCreateLogo from '../../assets/t-create-logo.png'
import tCreateLogo2x from '../../assets/t-create-logo@2x.png'
import { ReactComponent as AboutIcon } from '../../assets/menu/about.svg'
import { ReactComponent as ProjectsIcon } from '../../assets/menu/projects.svg'
import { ReactComponent as CrewIcon } from '../../assets/menu/crew.svg'
import { ReactComponent as ContactIcon } from '../../assets/menu/contact.svg'

type NavbarProps = {
  toggle: () => void
}

const Navbar: React.FC<NavbarProps> = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState<boolean>(false)

  const changeNav = () => {
    if (window.scrollY >= 100) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop()
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              <img src={tCreateLogo} srcSet={`${tCreateLogo2x} 2x`} alt="" />
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to="onas"
                  smooth
                  duration={500}
                  spy
                  hashSpy
                  offset={-150}
                >
                  <AboutIcon />
                  O nas
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="projekty"
                  smooth
                  duration={500}
                  spy
                  hashSpy
                  offset={-150}
                >
                  <ProjectsIcon />
                  Projekty
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="zespol"
                  smooth
                  duration={500}
                  spy
                  hashSpy
                  offset={-150}
                >
                  <CrewIcon />
                  Zespół
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="kontakt"
                  smooth
                  duration={500}
                  spy
                  hashSpy
                  offset={-150}
                >
                <ContactIcon />
                  Kontakt
                </NavLinks>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
